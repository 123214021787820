<template>
    <div class="title-flex">
        <h1>Mes offres <span class="number">{{ offres.length }}</span></h1>
        <router-link :to="{ name: 'offre_create'}" class="btn btn-primary"><i class="fa fa-plus"></i> Créer une offre</router-link>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox">
                <div class="ibox-content" v-if="offres.length > 0">
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover dataTables-example" >
                            <thead>
                                <tr>
                                    <th>Titre</th>
                                    <th>Type</th>
                                    <th>Etablissement</th>
                                    <th class="text-center">Etat</th>
                                    <th class="table-action">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="offre in offres" :key="offre.id">                                
                                    <th>{{ offre.titre }}</th>
                                    <td>{{ offre.type.libelle }}</td>
                                    <td>{{ offre.etablissement.titre }}</td>
                                    <td class="text-center">{{ offre.etat }}</td>
                                    <td class="table-action">
                                        <router-link :to="{ name: 'offre_create', params: { uuid: offre.uuid }}" title="Editer l'offre" v-if="offre.etat == 'D'"><i class="fa fa-edit"></i></router-link>
                                        <router-link :to="{ name: 'offre', params: { uuid: offre.uuid }}" title="Voir l'offre"><i class="fa fa-eye"></i></router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
  name: 'Offres',
  data() {
    return {  
    }
  },
  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    fetchData () {      
      this.$store.dispatch('getOffres');
    }
  },
  computed: {
    offres () {
      return this.$store.state.offres.offres
    },
  },
  components: {
  },
};
</script>